@import "./../../../styles/variables";

.ui-modal {

    .ant-modal-content {
        padding: 1rem;

        .ant-modal-header {
            border: 0;
            position: relative;
            pointer-events: none;

            .ant-modal-title {
                font-size: 24px;
                font-weight: bold;
            }
        }

        .ant-modal-body {
            position: relative;

            .ui-modal--subtitle {
                margin: 0;
                position: absolute;
                top: 0;
                left: 1.5rem;
                color: $menu-font-color;
                font-size: 16px;
            }
        }

        .ant-modal-footer {
            border: 0;
            display: flex;
            justify-content: space-between;
            position: relative;

            .ant-btn {
                width: 48%;
                height: 40px;
                border-radius: 10px;

                &.ant-btn-loading {
                    width: 48% !important;
                }
            }
        }

        .ant-modal-close-x {
            position: absolute;
            top: 1rem;
            right: .75rem;
            font-size: 14px;
            pointer-events: all;
        }

    }
}