@import "src/styles/variables";

.dropdown-field {
  position: relative;

  .dropdown-field__title {
    color: $form-label-color;
    margin-bottom: 5px;
  }

  .prefix-element {
    display: inline;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }

  .prefix-element {
    left: 10px;
  }


  .ant-select {
    width: 100%;

    .ant-select-selector {
      padding: 0 1rem !important;
      border: .5px solid $table-border !important;
      height: 40px !important;
      border-radius: 10px !important;

      // .ant-select-selection-search {
      // input.ant-select-selection-search-input {}
      // }

      .ant-select-selection-item {
        padding: .5rem 0;
        line-height: inherit;

      }

      .ant-select-selection-placeholder {
        padding: .5rem 0;
        line-height: inherit;
      }
    }

    .ant-select-arrow {

      .custom-arrow {

        &::before {
          font-size: 6px;
        }
      }
    }

  }

  &.dropdown-field-with-prefix {
    .ant-select {
      .ant-select-selector {
        padding: 0 2rem !important;
      }
    }
  }

  //   .ant-select-multiple .ant-select-selection-placeholder {
  //     left: 15px;
  //   }

  //   .ant-select-multiple .ant-select-selection-search {
  //     margin-inline-start: -2px;
  //   }

  //   .ant-select-multiple .ant-select-selection-search:first-child .ant-select-selection-search-input {
  //     margin-left: 0;
  //   }

  //   .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  //     height: 100%;
  //     padding-left: 4px;
  //   }

  //   .ant-select {
  //     width: 100%;
  //   }

  //   .ant-select-single .ant-select-selector .ant-select-selection-item,
  //   .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  //     line-height: 35px !important;
  //   }
  // }

  // .dropdown-field-dark {
  //   .ant-select-selector {
  //     background: transparent !important;

  //     .ant-select-selection-item {
  //       color: white !important;
  //     }
  //   }

  //   .ant-select-arrow {
  //     color: white;
  //   }
}

.ant-select-dropdown.dropdown-field--dropdown {
  border-radius: 12px;

  .rc-virtual-list-holder {
    padding: 1rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-select-item.ant-select-item-option {
    padding: .5rem 1rem;
    border-radius: 10px;

    &.ant-select-item-option-selected {
      background: $tab-header-bg;
    }
  }
}