@import "../../styles/variables";

.auth-wrapper{
  background: url("../../assets/Auth-bg-image.png") no-repeat center;
  background-size: cover;
  overflow: hidden;
}

.auth-form,.auth-image {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  //animation: fadeIn 1s linear ;
}

.auth-form{
  background: #FFFFFF;
  margin-top: 23vh;
  padding: 40px;
  border-radius: 14px;
  width: 45%;
  margin-right: 4%;
}

@keyframes fadeIn {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

.auth-image{
  height: 100vh;
  text-align: center;
  width: 47%;
  margin:0 0 0 4%;
  .auth-image__logo{
    margin-top: 42.5vh;
    width: 55%;
  }
}

.auth-form__header{
  font-size: 24px;
  font-weight: 700;
  color: #000000;
}

.auth-form__field{
  margin: 32px 0 0 0;
}

.form-label{
  color: $form-label-color;
  font-size: 14px;
  margin-bottom: 5px;
}

.auth-form__forgot{
  color: $primary-color;
  font-size: 15px;
  text-align: center;
  width: 40%;
  margin: 23px auto 0 auto;
  div{
    cursor: pointer;
  }
}

.auth-form__action{
  margin-top: 32px;
  width: 100% !important;
  height: 40px !important;
  font-size: 15px !important;
  border-radius: 10px !important;
  &.ant-btn-loading{
    width: 100% !important;
  }
}
