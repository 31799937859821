@import "./../../../styles/variables";

.status-toggle__container {
    padding: .75rem 1.5rem;
    border-radius: 10px;
    border: 1px solid $danger-color;
    background-color: $danger-bg;
    font-weight: bold;
    font-size: 16px;
    color: $danger-color;

    .ant-switch {
        background-color: $danger-color !important;
    }

    &.active {
        border: 1px solid $primary-color;
        color: $primary-color;
        background-color: $primary-bg;

        .ant-switch.ant-switch-checked {
            background-color: $primary-color !important;
        }
    }
}