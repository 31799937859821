.admin-dashboard__container {
    .admin-dashboard__table-wrapper {
        margin: 0 !important;
        padding: 2rem;
        background: #F8FAFB;

        .table-component .ant-table {
            .ant-table-container {
                .ant-table-content {
                    .ant-table-thead {
                        .ant-table-cell {
                            font-weight: bold !important;
                        }
                    }
                }
            }
        }
    }
}