@import "./../../../styles/variables";

.repair-list__table-header {
    width: 100%;

    .version-form__field {

        .ant-btn {
            // width: 230px;
            text-align: left;
            overflow-x: hidden;

            .icon-dropdown {
                margin: 0 0 0 0.5rem;
                font-size: 6px;
            }
        }
    }
}


.version-menu {
    border: 0 !important;
    height: fit-content;
    max-height: 350px;
    overflow-y: scroll;
    border-radius: 12px !important;

    &::-webkit-scrollbar {
        display: none;
    }

    .new-version {
        color: $primary-color !important;

        i::before {
            padding-right: 0.5rem;
        }
    }

    .selected {
        background: hsla(169, 51%, 39%, 0.2) !important;
    }

    .default {
        position: relative;

        i::before {
            position: absolute;
            top: 50%;
            right: .75rem;
            transform: translateY(-60%);
            margin-left: .5rem;
            padding: .25rem .20rem;
            font-size: 8px;
            background-color: $primary-color;
            color: white;
            border-radius: 100px;
        }
    }

    &:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: transparent;
    }

    .ant-menu-item:focus,
    .ant-menu-item:hover {
        background: $table-even-row-bg;
    }

    li {
        padding: .6rem 2rem 0.25rem 1rem;
        margin: .25rem 1rem;
        // height: 47px;
        color: #000;
        font-size: 15px;
        font-weight: 500;
        border-radius: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover,
        &:focus {
            color: #000;
        }

        p {
            margin: 0;
        }

        span {
            color: $dark-grey;
            font-size: 12px;
        }
    }
}