@import "./../../../styles/variables";

.user-form__field {

    .input-field {
        .ant-input-affix-wrapper {
            padding: 0;

            .ant-input-prefix {
                margin: 0;
            }

            .ant-input {
                padding-left: .75rem !important;
            }
        }
    }
}