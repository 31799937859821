@import './../../../styles/variables';

.attachment-container {

  .ant-skeleton {
    width: 100%;
    height: 60px;
    margin: 1rem 0 0;

    .ant-skeleton-button {
      width: 100%;
      height: inherit;
      border-radius: 10px !important;

    }
  }

  .attachment-image__uploader {
    display: none;
  }

  .attachment-document__uploader {
    margin: 1rem 0 0;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    min-height: 160px;
    border: 2px dashed #cfd5da;
    background-color: #edf1f5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    i::before {
      font-size: 32px;
    }

    h3 {
      margin: 1rem 0 0 0;
      text-align: center;
      color: rgba(118, 135, 152, .5);
      font-size: 18px;

      .browse-link {
        font-size: 14px;
        color: $primary-color;
      }
    }
  }

  &.image-only {
    min-height: 40px;

    .attached-image__title {
      margin: 1rem 0;
      text-align: left;
    }

    .attachment__image__container {
      margin: 1rem 0;
      width: 100%;
      height: 200px;
      position: relative;
      border: 0.5px solid #cfd5da;
      height: 200px;
      border-radius: 10px;

      i::before {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        padding: 0.5rem;
        border-radius: 100px;
        background: red;
        color: #fff;
        opacity: 0;
        transition: all .2s;
        cursor: pointer;
      }

      .attachment__image {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
      }
    }

    .attachment-image__uploader {
      margin: 1rem 0 0;
      padding: 0 1.5rem;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $form-label-color;
      border-radius: 10px;
      position: relative;
      border: 2px dashed $table-border;
      background-color: $file-upload-bg;
      cursor: pointer;

      img {
        width: 15px;
        margin-right: .5rem;
      }
    }

    .attachment__placholder,
    .attachment__title {
      display: none;
    }

    &.selected {
      padding: 0;
      background: none;
      min-height: 200px;
    }
  }

  &.selected {
    margin: 2rem 0 1rem;
    padding-left: 3rem;
    background-image: url('./../../../assets/Multicolor\ icons\ -\ SVG/csv\ preview.svg');
    background-repeat: no-repeat;
    background-position: 1rem center;
    background-size: 20px 24px;
    overflow: hidden;
    border: 0.5px solid $table-border;
    // height: 60px;
    min-height: 60px;
  }

  .attachment__placholder {
    margin: 2rem auto 0;
    text-align: center;
    border-radius: 50px;
    cursor: pointer;

    .icon-upload-photo::before {
      font-size: 36px;
      opacity: .5;
    }
  }

  .attachment__title {
    color: hsla(210, 14%, 53%, 0.5);
    font-size: 18px;
    margin: 1rem;

    .helper {
      font-size: 13px;
      color: $primary-color;
      opacity: 1;
    }
  }

  .attachment__image__container {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .attachment__image {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }


    i::before {
      position: absolute;
      top: 0.5rem;
      right: .5rem;
      padding: .5rem;
      border-radius: 100px;
      background: red;
      color: white;
      opacity: 0;
      transition: all 200ms;
    }

    &:hover {
      i::before {
        opacity: 1;
      }
    }
  }

  .attachment__pdf--container {
    width: 100%;
    height: 100%;
    text-align: left;
    padding-top: 18px;
    font-size: 16px;
    position: relative;

    .attachment__pdf--remove {
      font-size: 10px;
      position: absolute;
      top: 1.5rem;
      right: 1rem;
      cursor: pointer;

      &:hover::before {
        color: red;
      }
    }

    &.selected {
      margin: 2rem 0 1rem;
      padding-left: 3rem;
      background-image: url('./../../../assets/Multicolor\ icons\ -\ SVG/csv\ preview.svg');
      background-repeat: no-repeat;
      background-position: 1rem center;
      background-size: 20px 24px;
      overflow: hidden;
      border: 0.5px solid $table-border;
      height: 60px;
      min-height: 60px;
      border-radius: 10px;
    }
  }
}

.attachment__pdf--container::-webkit-scrollbar,
.attachment__pdf--container .attachment__pdf::-webkit-scrollbar,
.attachment__pdf--container .attachment__pdf>*::-webkit-scrollbar {
  display: none;
}