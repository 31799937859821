@import "./../../../../styles/variables";

.container-item-form.reset-form {
    position: absolute;
    top: -2.3rem;
    right: 4rem;

    &:hover {
        cursor: pointer;
        color: $primary-color;
    }

}

.hide-disabled {

    .input-field {
        input {
            color: black !important;
            background: White !important;
        }
    }
}