@import "./../../../styles/variables";

.customer-card {
    padding: 1rem 1.5rem;
    width: 520px;
    border-radius: 12px;
    box-shadow: $table-shadow;
    border: 0.5px solid $table-border;
    background: white;

    .customer-card--key,
    .customer-card--value {
        font-size: 15px;
        margin: .5rem 0;
    }

    .customer-card--value {
        text-shadow: 0px 0px 0px black;
    }
}