@import "./../../../../styles/variables";

.container-form-body-actions__container {
    min-height: 88px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap !important;
    padding: 1rem 1.5rem 2rem 2rem;

    .ant-col {
        display: inline-flex;

        &:first-child>* {
            margin-right: 1rem;
        }

        &:nth-child(2)>* {
            margin-left: 1rem;
        }


        .container-form-body-actions__loader {
            .ant-space-item {
                .ant-skeleton.ant-skeleton-element.ant-skeleton-active {
                    .ant-skeleton-button.ant-skeleton-button-lg.ant-skeleton-button-round {
                        width: 120px;
                        height: 40px;
                        border-radius: 10px;
                    }
                }
            }
        }
    }

    .secondary-button {
        color: white !important;
        background: $action-btn-bg !important;
    }

}

.status-dropdown__container {
    display: inline-flex;

    .ant-btn {
        width: 150px;
        color: #000;
        text-transform: capitalize;
        text-align: left;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;

        span {
            width: 130px;
            font-size: 14px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    &::before {
        content: "Status";
        font-size: 15px;
        height: 40px;
        padding: .7rem .75rem .75rem;
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px;
        background: $table-border;
    }
}



.activity-status-menu {
    border: 0 !important;
    width: 250px;
    height: fit-content;
    max-height: 350px;
    overflow-y: scroll;
    border-radius: 12px !important;

    &::-webkit-scrollbar {
        display: none;
    }

    &:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: transparent;
    }

    .status-item {
        text-transform: capitalize;
    }

    .ant-menu-item:focus,
    .ant-menu-item:hover {
        background: $table-even-row-bg;
    }

    li {
        padding: .6rem .25rem 0.25rem 1rem;
        margin: .25rem auto;
        width: 200px;
        height: 40px;
        color: #000;
        font-size: 15px;
        font-weight: 500;
        border-radius: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &.delete-form {
            color: $danger-color !important;
        }

        &:hover,
        &:focus {
            color: #000;
        }
    }
}

.invoice-history-modal.ui-modal {
    .ant-modal-content {
        .ant-modal-footer {
            display: none;
        }
    }
}