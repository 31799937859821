@import './../../../styles/variables';

.ant-modal.ui-modal.repair-form-modal {
    width: 80vw !important;
    max-width: 1200px !important;

    .ant-modal-content {
        padding: 0;

        .ant-modal-header {
            border-radius: 12px;
            padding: 2.5rem 2.5rem 2rem;
        }

        .ant-modal-body {
            padding: 0 0 2.5rem;

            .ant-tabs {
                .ant-tabs-nav {
                    .ant-tabs-nav-wrap {

                        .ant-tabs-nav-list {
                            padding: 0 2.5rem;
                            width: 100%;
                            background: $tab-header-bg;

                            .ant-tabs-tab {
                                .ant-tabs-tab-btn {
                                    span {
                                        font-size: 18px;
                                        color: #000;
                                    }

                                    .icon-done::before {
                                        margin: 0.25rem .5rem 0;
                                    }
                                }
                            }

                            .ant-tabs-ink-bar {
                                background: $primary-color;
                            }
                        }
                    }
                }

                .ant-tabs-content-holder {
                    padding: 0 2.5rem;

                    .ant-tabs-content {

                        .repair-form {
                            margin-top: 1rem;

                            .repair-row {
                                margin-bottom: 1.5rem;
                                padding-bottom: 1.5rem;
                                border-bottom: 1px solid $table-border;
                            }

                            .repair-form--title {
                                font-size: 16px;
                                font-weight: 500;
                            }

                            .repair-form__actions {
                                display: flex;
                                justify-content: end;

                                .ant-btn-default {
                                    margin: 0 .25rem;
                                    background: $table-border;
                                    color: #000 !important;
                                }

                                .ant-btn-primary {
                                    margin: 0 .25rem;
                                    background: $action-btn-bg !important;
                                    color: #fff !important;

                                    &.success {
                                        background: $primary-color !important;
                                    }
                                }
                            }

                            &.disabled {

                                .repair-form--title,
                                .repair-form__field {
                                    opacity: .5;
                                    pointer-events: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}