@import "./../../../../../styles/variables";

.container-comment-card {
    padding: 1rem 0 0;
    border-bottom: 1px solid $tab-border;
    width: 65vw;
    max-width: 1000px;

    .container-comment-card__header {
        .container-comment-card__header--pic {
            width: 40px;
            height: 40px;
            border-radius: 100px;
            background: rgb(235, 138, 138);
            text-transform: uppercase;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
        }

        .container-comment-card__header--name {
            padding: 0 0 0 1rem;
            text-transform: capitalize;

            p {
                margin: 0;
            }

            .date {
                font-style: italic;
                color: $form-label-color;
                font-size: 12px;
            }
        }


    }

    .container-comment-card--desc {
        position: relative;
        padding-right: 3.75rem;

        &.truncate {
            p {
                display: -webkit-box;
                // cursor: auto;
            }
        }

        p {
            cursor: pointer;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .see-more {
            cursor: pointer;
            white-space: nowrap;
            color: $form-label-color;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

}