@import "src/styles/variables";

.table-component {
  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        .ant-table-content {
          border-radius: 12px;
          box-shadow: $table-shadow;
          border: 0.5px solid $table-border;

          table {


            .ant-table-thead {
              background-color: #EDF1F5;
              border-radius: 12px 0 12px 0;


              tr {
                th.ant-table-cell.table-hover-actions {
                  color: #EDF1F5;
                  //width:100%;
                  //display: none;
                }

              }

              tr {
                

                th {
                  font-size: 14px;
                  font-weight: 500;
                  background: transparent;
                  border-bottom: 0.5px solid $table-border;

                  .ant-table-column-sorters {
                    .ant-table-column-sorter {
                      .ant-table-column-sorter-inner {
                        .anticon.active {
                          color: $primary-color;
                        }
                      }
                    }
                  }
                }

                th:first-child {
                  border-top-left-radius: 12px;
                }

                th:last-child {
                  border-top-right-radius: 12px;
                }
              }

            }

            .ant-table-tbody {

              tr {
                td {
                  border: 0;
                }

                &.selection-disabled {
                  .ant-table-cell.ant-table-selection-column {
                    .ant-checkbox-wrapper {
                      opacity: .3;
                      pointer-events: none;
                      cursor: not-allowed;
                    }
                  }
                }
              }

              tr:last-child {
                border-radius: 0 0 12px 12px;

                td:first-child {
                  border-radius: 0 0 12px 12px;
                }

                td:last-child {
                  border-radius: 0 0 12px 12px;
                }

                &.ant-table-placeholder {
                  .ant-table-cell {
                    padding: 0 0.5rem;
                  }
                }
              }

              tr:nth-child(even) {
                td {
                  background: #fff;
                }
              }

              tr:nth-child(odd) {
                td {
                  background: $table-even-row-bg;
                }
              }

              tr {
                td {
                  .table-actions {
                    i {
                      cursor: pointer;
                      padding: .5rem;

                      &:hover::before {
                        color: $primary-color;
                      }

                      &.icon-delete:hover::before {
                        color: $danger-color;
                      }
                      &.icon-comment::before{
                        color: #EDF1F5;
                      }
                      &.icon-image::before{
                        color:#edf1f5;
                      }
                      &.comment_green::before {
                        color: $icon-hover-color;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .ant-pagination.ant-table-pagination {

      .ant-pagination-total-text {
        position: absolute;
        left: 0;

        p {
          font-size: 14px;
          margin: 0;
        }
      }

      .ant-pagination-prev,
      .ant-pagination-next {
        margin: 0;
        min-width: 24px;
        height: 24px;

        .ant-pagination-item-link {
          min-width: 24px;
          height: 24px;
          border: 0;
          line-height: 25px;

          .anticon {
            color: $primary-color;
          }
        }
      }

      .ant-pagination-item {
        margin: 0;
        min-width: 24px;
        height: 24px;
        border: none;

        a {
          margin: 0;
          min-width: 24px;
          height: 24px;
          line-height: 25px;
          border-radius: 6px;
          background-color: #fff;
          color: #000;

          &:hover {
            color: #000;
          }
        }


        &.ant-pagination-item-active {

          a {
            background-color: $primary-color;
            color: #fff;
          }
        }
      }

      .ant-pagination-jump-prev,
      .ant-pagination-jump-next {
        &:hover {

          .ant-pagination-item-link {
            .ant-pagination-item-container {

              .ant-pagination-item-ellipsis {
                opacity: 1;
              }
            }
          }
        }
      }

      .ant-pagination-options {
        .ant-select.ant-pagination-options-size-changer {
          display: none;

        }
      }
    }

    &.disable-select-all {
      .ant-spin-nested-loading {
        .ant-spin-container {
          .ant-table {
            .ant-table-container {
              .ant-table-content {
                table {
                  .ant-table-thead {
                    tr {
                      .ant-table-cell.ant-table-selection-column {
                        .ant-table-selection {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.anticon.anticon-double-right.ant-pagination-item-link-icon,
.anticon.anticon-double-left.ant-pagination-item-link-icon {
  display: none !important;
}

.ant-pagination-item-ellipsis {
  opacity: 1 !important;
}

.table-hover-actions {
  //position: relative;
  //position: absolute !important;
  height: 100%;
  //display: inline;
  text-align:right;
  //align-items: center;
  //visibility: hidden;
  //background: white !important;
  right: 0;
  //width:100%;
  }

.ant-table-row {
  position: relative;

  &:hover .table-hover-actions {
    visibility: visible;
  }
}