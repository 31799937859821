@import "./../../../../styles/variables";

.container-activity-timeline__container {

    &.ant-dropdown-open {
        border-color: $primary-color !important;
        background: $primary-color !important;

        span {
            color: white;

            &::before {
                color: white !important;
            }
        }
    }
}

.container-activity-timeline {

    ul {
        width: 300px;
        padding: 2rem;
        border-radius: 12px;

        li {
            .ant-timeline-item-head.ant-timeline-item-head-custom {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 25px;
                height: 25px;
                background: $status-grey-bg;
                border-radius: 50px;

                .icon-quote-accepted,
                .icon-quote-approved {
                    font-size: 10px;
                }

            }

            &.active {
                .status-name {
                    color: $success-color;
                }

                .ant-timeline-item-head.ant-timeline-item-head-custom {
                    background: $status-success-bg;

                    i::before {
                        opacity: .6;
                        color: $success-color;
                    }
                }
            }

            &.pending {

                .status-name {
                    color: $status-yellow;
                }

                .ant-timeline-item-head.ant-timeline-item-head-custom {
                    background: $status-yellow-bg;

                    i::before {
                        color: $status-yellow;
                    }
                }
            }

            .status-name {
                color: $status-grey;
                text-transform: capitalize;
            }

            &:last-child {
                padding: 0;

                .ant-timeline-item-tail {
                    display: none;
                }
            }
        }
    }
}

.ui-modal.timeline-edit-date-modal {
    .ant-modal-content {
        .ant-modal-body {
            .ant-btn {
                width: 100%;
            }
        }
    }
}