@import "src/styles/variables";

.input-field {
  // height: 40px !important;

  input {
    height: 100%;
  }

  .ant-input-affix-wrapper {
    height: 100%;
    border-radius: 12px;
    padding-left: 20px !important;
    border-color: $table-border;


    &:focus,
    &:hover,
    &.ant-input-affix-wrapper-focussed {
      border-color: $table-border;
    }

    .ant-input-prefix {
      margin-right: 15px;
    }
  }

  .ant-input {
    height: 40px;
    border-radius: 12px;
    padding-left: 20px !important;
  }

  textarea.ant-input {
    height: unset !important;
  }
}

.error-message {
  color: $danger-color;
}