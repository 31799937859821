@import "./../../styles/variables";

.reports__wrapper {
    .reports__tabs {
        .ant-tabs {
            .ant-tabs-nav {
                .ant-tabs-nav-wrap {
                    padding: 0 2rem;
                }
            }
        }
    }

    .reports__content {
        padding: 1rem 2rem;


        .ant-btn.secondary {
            background: $action-btn-bg;

            span {
                color: white;
            }
        }

        .report__actions {
            margin-top: 1rem;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            gap: 1rem;
        }

        .form-hint {
            font-size: 12px;
            font-style: italic;
            display: flex;
            align-items: center;

            img {
                margin-right: .25rem;
                width: 12px;
                height: 12px;
            }
        }
    }
}