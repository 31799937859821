.container-carousel {
    width: fit-content;
    height: fit-content;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background: hsla(0, 0%, 0%, 0.9);

    .container-carousel--actions {
        padding: 25px;
        width: 100vw;
        color: white;
        justify-content: space-between;
        align-items: center;
        text-transform: capitalize;

        .ant-col {
            display: flex;
            align-items: center;
        }

        i {
            margin: 0 .5rem;
            cursor: pointer;

            &::before {
                color: white;

            }

            &.icon-image::before {
                font-size: 18px;
                cursor: auto;
                color: #D93025;
            }

            &.icon-download::before {
                font-size: 16px;
            }
        }

        span {
            margin: 0;
            font-size: 16px;
        }
    }

    .ant-carousel {
        width: 100vw;
        height: calc(100vh - 75px);

        .slick-slider,
        .slick-list,
        .slick-track {
            height: 100%;
        }

        .slick-track {
            div {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .carousel-image {
            max-height: 80vh;
            display: block !important;
            margin: auto;
        }
    }

    .carousel-nav {
        width: 50px;
        height: 50px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &.next-item {
            right: 2rem;
        }

        &.prev-item {
            left: 2rem;
        }
    }
}