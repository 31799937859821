@import "src/styles/variables";

.repair-list__container {

    .repair-list__table-header {
        padding: 0 2rem;
    }

    .repair-list__table {
       margin: 2rem;
    }
}