.table-loader__container {
    .ant-skeleton {
        padding: .5rem 0;
        width: 100%;
        display: block;

        &:first-child {
            padding-top: 1rem;
        }

        &:last-child {
            padding-bottom: 1rem;
            border: none;
        }

        .ant-skeleton-button {
            height: 12px;
            display: flex;
            width: 100%;
            border-radius: 12px !important;
        }
    }
}