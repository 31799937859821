@import "./../../../styles/variables";

.container-header__container {
    padding: 2rem 2rem 0;
    background-color: white;

    .container-header__title {

        .container-header__title--back {
            color: $form-label-color;
            cursor: pointer;
        }

        h1 {
            margin: 0;
            font-size: 32px;
            font-weight: bold;
        }

        .container-header__icon {
            margin: 0 1rem;
            cursor: pointer;
            font-size: 16px;

            &:hover::before {
                color: $primary-color;
            }
        }

        .container-header__title--thumb {
            margin: 0 .5rem 0 0;
            width: 50px;
            height: 50px;
            border-radius: 12px;
            cursor: pointer;
        }

    }

    .container-header__nav {
        padding: 1rem;
        color: $form-label-color;

        .container-header__nav-item {
            margin: 0 .5rem;
            padding: .25rem .35rem;
            border-radius: 100px;
            background: $tab-border;
            cursor: pointer;

            &.disabled {
                cursor: not-allowed;
            }
        }
    }

    .container-header__details {
        margin-top: 1rem;

        .container-header--title,
        .container-header--subtitle {
            margin: 0 1rem 0 0;
            font-size: 15px;
            font-weight: 500;
            text-transform: capitalize;
        }
        .container-header--subtitle-no-caps{
            margin: 0 1rem 0 0;
            font-size: 15px;
            font-weight: 500;
        }

        .container-header--title {
            font-size: 12px;
            color: $form-label-color;
        }

        .comment-section {
            margin-top: 16px;
        }

    }
    .icon-comment{
        color: $primary-color;
    }
}