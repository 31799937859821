@import "variables";

.ant-modal-content {
  border-radius: 12px !important;
}

.ant-select-item-option-content,
.ant-select-selection-item {
  text-transform: capitalize;
}

/* Button */

.ant-btn,
.ant-btn:hover,
.ant-btn:active,
.ant-btn:focus {
  background-color: #fff;
  border-color: $dark-grey;
  color: $dark-grey;

  &:disabled {
    opacity: .75;
  }
}

.ant-btn-primary {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  color: #fff !important;
}

/* Input */
.ant-input {
  border-color: $table-border;

  &:hover,
  &:focus,
  &:active {
    border-color: $table-border;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*Checkbox */
.ant-checkbox-wrapper {
  .ant-checkbox {

    .ant-checkbox-inner {
      border-color: $primary-color !important;
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background: $primary-color;
      }
    }

    &.ant-checkbox-indeterminate {
      .ant-checkbox-inner {
        &::after {
          background: $primary-color;
        }
      }
    }
  }
}

.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: hidden;
}

/* Tabs */
.ant-tabs {
  .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          .ant-tabs-tab-btn {
            font-size: 15px;
            color: $form-label-color;
          }

          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              font-weight: 500;
              color: $primary-color;
            }
          }
        }

        .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
          height: 3px;
          background: $primary-color;
        }
      }
    }
  }
}

/* Datepicker */
.ant-picker-decade-btn {
  color: black !important;
}

.ant-picker-cell {
  .ant-picker-cell-inner {
    height: 40px;
    padding: .5rem .5rem;
  }

  &.ant-picker-cell-selected {

    .ant-picker-cell-inner {
      background: $primary-color;
    }
  }
}

.ant-picker-today-btn {
  color: $primary-color !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: $primary-color !important;
}

/* Message */
.ant-message {
  .ant-message-notice {
    .ant-message-notice-content {
      .ant-message-custom-content {
        &.ant-message-info {
          .anticon {
            color: $primary-color
          }
        }
      }
    }
  }
}