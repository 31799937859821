@import "./../../../styles/variables";

.customer-list__container {
    height: 100%;
    background: $body-bg;

    .customer-list--tabs {
        margin-top: -1rem;

        .ant-tabs-nav-wrap {
            background-color: white;
            padding: 0 2rem;
            border-bottom: 1px solid $tab-border;
        }

        .ant-tabs-content-holder {
            padding: 1rem 2rem 2rem;
        }
    }
}