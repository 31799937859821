@import "src/styles/variables";

.search-form__field {
    width: 300px;
    height: 40px;

    .input-field {

        .ant-input-affix-wrapper {
            padding: 0 .5rem;
            height: 100%;
        }

        .ant-input {
            padding-left: 0 !important;
        }
    }
}

.table-filter__container {
    padding: 1.5rem !important;
    width: 500px;
    border-radius: 12px !important;

    .table-filter__header {
        margin-bottom: 1rem;

        .table-filter--title {
            margin: 0;
            font-size: 18px;
            font-weight: 400;
        }

        .table-filter--actions {
            font-size: 15px;
            font-weight: 500;

            span {
                margin-left: 0.5rem;
                cursor: pointer;
            }

            .reset {
                color: $menu-font-color;
            }

            .apply {
                color: $primary-color;
            }
        }
    }

    .table-filter__body {
        .dropdown-field {
            .dropdown-field__title {
                margin-bottom: 5px;
                color: $menu-font-color;
            }
        }
    }
}