/* Color palette */
$primary-color: #319583;
$secondary-color: #272363;
$light-white: #FFFFFF40;
$primary-light-color: #0D494E10;
$primary-black: #000000;
$dark-blue: #22509D;
$light-grey: #00000015;
$dark-grey: #8898AA;
$success-color: #056344;
$danger-color: #C92B3E;
$warning-color: #F3B21B;
$body-bg: #F8FAFB;
$table-bg: #F7FAFC;
$input-bg: #F8FBFC;
$file-upload-bg: #EDF1F5;
$sidebar-profile-bg:#F0F0F4;
$table-even-row-bg: #F6F8FA;
$tab-header-bg: #F2F5F8;
$action-btn-bg: #43495C;
$close-color: #F74A4A;
$form-label-color: #949EA9;
$icon-hover-color: #43C9B1;
$menu-font-color: #949EA9;
$table-border: #CFD5DA;
$tab-border:#E9EDF0;
$danger-bg: #da31251a;
$primary-bg: #3196841a;
$status-violet:#534693;
$status-orange:#DA672C;
$status-yellow:#E89319;
$status-blue:#21738A;
$status-grey:#818B98;
$status-pink:#ED2C6D;
$status-pink-bg:#FFEDF3;
$status-grey-bg:#EDEFF3;
$status-success-bg:#ABF5D3;
$status-danger-bg:#FFC1D4;
$status-violet-bg:#EAE6FE;
$status-blue-bg:#B2EAFD;
$status-orange-bg:#FFDFB4;
$status-yellow-bg:#FFF1B4;
$activity-repair: #F99F1C;
$activity-quote:#773195;
$activity-quote-bg: #7831961a;
$activity-repair-bg: #f9a01a1a;
/* Shadows */
$primary-shadow: 10px 20px 40px #AEAEAE24;
$secondary-shadow: 0px 3px 6px #2C28281C;
$table-shadow: 0px 0px 24px #3E3F5E0F;

/* Gradients */
$primary-gradient: transparent linear-gradient(114deg, #272363 0%, #514B8F 100%) 0% 0% no-repeat padding-box;
$primary-button-gradient: transparent linear-gradient(112deg, #272363 0%, #514B8F 100%) 0 0 no-repeat padding-box !important;
$primary-header-button-gradient: transparent linear-gradient(119deg, #272363 0%, #514B8F 100%) 0% 0% no-repeat padding-box;

/* Spacer variables */
$spacer-x : 1rem;
$spacer-y : 1rem;

/* Border */
$form-input-border-color: #d9d9d9;