@import "./../../../styles/variables";

.container-detail__container {
    min-height: 100%;
    background: #F8FAFB;


    .ant-tabs-nav {
        margin: 0 !important;
        padding: 0 2rem;
        background-color: white;
    }

    .container-form {
        margin-top: 1.5rem;
    }

    // .activities__container {
    // }

    .ant-tabs-content-holder {
        padding: 0 2rem;
        min-height: calc(100vh - 175px - 47px);
        position: relative;


        .container-activity--empty {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 7rem 1rem 1rem;
            width: fit-content;
            color: $form-label-color;
            font-size: 16px;
            background: url("./../../../assets/empty-activities.svg");
            background-size: 110px 110px;
            background-position: top center;
            background-repeat: no-repeat;

            p {
                margin: 0;
            }
        }
    }

    .container-form__container {
        margin: 2rem auto !important;
        border: 0 !important;

        .ant-collapse-item {
            margin-bottom: 2rem;
            border: 0 !important;


            .ant-collapse-header {
                padding: 0 !important;
                border: 0 !important;

                .ant-collapse-header-text {
                    .icon-edit {
                        display: none;
                    }
                }
            }


            &.ant-collapse-item-active {

                .ant-collapse-header {

                    .ant-collapse-header-text {

                        .container-form__header {
                            box-shadow: none;

                            .icon-edit {
                                margin: 0 .5rem;
                                display: inline-block;
                                cursor: pointer;

                                &::before {
                                    font-size: 10px;
                                }
                            }

                            &.quote {
                                border-color: $activity-quote;
                            }

                            &.repair {
                                border-color: $activity-repair;
                            }
                        }
                    }
                }
            }

            .ant-collapse-content {
                border: 0 !important;

                .ant-collapse-content-box {
                    padding: 0 !important;
                }
            }
        }
    }
}