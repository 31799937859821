@import "./../../../../styles/variables";

.container-logs {
    padding: 2rem 0;

    .container-logs__list {
        margin: 2rem 0;
        border-radius: 12px;
        overflow: hidden;
        border: 1px solid $table-border;

        .container-logs__list-item {
            padding: 1rem;
            background: $table-even-row-bg;

            &:nth-child(2n+1) {
                background: white;
            }

            .date {
                font-size: 15px;
                margin-right: 1rem;
            }

            .type {
                color: #356AE6;
            }

            .user {
                color: $activity-repair;
            }
        }
    }
}