@import "variables";

/* Helpers CSS */

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.float-right {
  float: right !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-bold {
  font-weight: bold;
}

.text-primary {
  color: $primary-color;
}

.text-light-grey {
  color: $light-grey;
}

.text-dark-grey {
  color: $dark-grey;
}

.text-white {
  color: #FFFFFF !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.bb-5 {
  border-bottom: ($spacer-y * 1.25) solid $primary-color !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.float-none {
  float: none !important;
}

.primary-black {
  color: $primary-black !important;
}

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100% !important;
}


/* Padding */

.p-0 {
  padding: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

/* Margins */

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: ($spacer-y * .25) !important;
}

.mt-2 {
  margin-top: ($spacer-y * .5) !important;
}

.mt-3 {
  margin-top: ($spacer-y * .75) !important;
}

.mt-4 {
  margin-top: ($spacer-y * 1) !important;
}

.mt-5 {
  margin-top: ($spacer-y * 1.25) !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-2 {
  margin-bottom: ($spacer-y * .5) !important;
}

.mb-3 {
  margin-bottom: ($spacer-y * .75) !important;
}

.mb-4 {
  margin-bottom: ($spacer-y * 1) !important;
}

.mb-5 {
  margin-bottom: ($spacer-y * 1.25) !important;
}

.ml-2 {
  margin-left: ($spacer-x * .5) !important;
}

.ml-3 {
  margin-left: ($spacer-x * .75) !important;
}

.ml-4 {
  margin-left: ($spacer-x * 1) !important;
}

.ml-5 {
  margin-left: ($spacer-x * 1.25) !important;
}

.mr-1 {
  margin-right: ($spacer-x * .25) !important;
}

.mr-2 {
  margin-right: ($spacer-x * .5) !important;
}

.mr-3 {
  margin-right: ($spacer-x * .75) !important;
}

.mr-4 {
  margin-right: ($spacer-x * 1) !important;
}

.mr-5 {
  margin-right: ($spacer-x * 1.25) !important;
}

/* overflow */
.text-overflow {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.view-more {
  text-decoration: underline;
  font-size: 14px;
  margin-left: 7px;
  cursor: pointer;
}

/* icon */
.green-icon:before {
  color: $primary-color !important;
}

.status {
  margin: 0;
  padding: .25rem .75rem;
  width: fit-content;
  border-radius: 15px;
  font-size: 13px;
  white-space: nowrap;
  color: $status-grey;
  background: $status-grey-bg;
  text-transform: capitalize;

  // , :, :, :, :,
  // , :, :, :, :
  &.void,
  &.invoice_void,
  &.inactive {
    color: $danger-color;
    text-shadow: 0 0 0 $danger-color;
    background: $status-danger-bg;
  }

  &.repair_done {
    color: $status-violet;
    text-shadow: 0 0 0 $status-violet;
    background: $status-violet-bg;
  }

  &.quote_issued {
    color: $status-yellow;
    text-shadow: 0 0 0 $status-yellow;
    background: $status-yellow-bg;
  }

  &.pending_customer_approval,
  &.repair_pending_admin_approval,
  &.pending_admin_approval {
    color: $status-blue;
    text-shadow: 0 0 0 $status-blue;
    background: $status-blue-bg;
  }

  &.ready_for_repair,
  &.ready_for_billing {
    color: $status-pink;
    text-shadow: 0 0 0 $status-pink;
    background: $status-pink-bg;
  }

  &.invoiced,
  &.idle,
  &.billed,
  &.active {
    color: $success-color;
    text-shadow: 0 0 0 $success-color;
    background: $status-success-bg;
  }
}

.amount-column {
  padding-right: 5% !important;
}