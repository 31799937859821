@import "src/styles/variables";

.app-header {
  padding: 2rem;

  &.bg-white {
    background-color: white;
  }

  .app-header__left,
  .app-header__right {
    display: flex;
    align-items: center;
  }

  .app-header__left {
    .go-back {
      cursor: pointer;
      display: inline-block;
      vertical-align: top;
      margin: 0 2rem 1rem 0;
      font-size: 14px;
      color: $menu-font-color;

      .anticon {
        margin-right: .25rem;
        color: $menu-font-color !important;
        font-weight: 800;
      }
    }
  }

  .app-header__right {
    text-align: right;
    vertical-align: baseline;
  }

  .app-header__icon,
  .app-header__title,
  .app-header__search,
  .app-header__action {
    display: inline-block;
  }

  .app-header__icon {
    margin: 0 1rem;
    font-size: 24px;
    cursor: pointer;

    &:before {
      color: $primary-color;
    }

    &.icon-edit {
      font-size: 16px;

      &:before {
        color: $menu-font-color;
      }

      &:hover:before {
        color: $primary-color;
      }
    }
  }

  .app-header__title {
    color: $primary-black;
    font-size: 32px;
    font-weight: bold;
    text-transform: capitalize;
    vertical-align: text-bottom;

    p {
      margin-bottom: 0;
    }
  }

  .app-header__search {
    vertical-align: middle;
    /*.ant-input-search{
      width: 275px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 12px #0000000A;
      border-radius: 8px;
      margin-right: 25px;
    }
    .ant-input-wrapper{
      padding: 0 10px;
    }
    .ant-input-affix-wrapper,.ant-input-group-addon .ant-btn{
      border: unset !important;
    }
    .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
      box-shadow: unset !important;
    }*/
  }

  .app-header__action {
    .app-header__button {
      height: 42px;
      border-radius: 8px;
      font-size: 14px;
      background: $primary-header-button-gradient !important;
    }
  }
}