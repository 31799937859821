@import "variables";
@import "helpers";
@import "icons";
@import "antOverride";

/* General component styles */
body {
  background-color: #FFFFFF;
  color: $primary-black;
}

body,
h1,
h2,
h3,
h4,
h5,
button,
input {
  font-family: 'SF Pro Display', sans-serif !important;
}

.app-wrapper {
  padding: 3rem 0 0 20%;
  width: 95%;
}