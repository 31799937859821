@import "./../../styles/variables";

.containers__container {
    height: 100%;
    background: $body-bg;

    .containers__tabs {

        .ant-tabs-nav {
            margin: 0;
            padding: 0 2rem;
            background: white;

            .ant-tabs-tab.ant-tabs-tab-disabled {
                display: none;
            }
        }

        .ant-tabs-content {
            display: block;
            padding: 1rem 2rem 2rem;


        }
    }
}