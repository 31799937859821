.user-management__container {
    .user-management__tabs {
        .ant-tabs {
            .ant-tabs-nav {
                .ant-tabs-nav-wrap {
                    padding: 0 2rem;
                }
            }
        }
    }

    .user-management__table {
        .user-management__table-header {
            padding: 0 2rem;
        }

        .user-management__table-body {
            padding: 1rem 2rem 2rem;
        }
    }
}