@import "./../../../styles/variables";

.double-btn__container {
    display: inline-flex;
    width: fit-content;
    justify-content: center;
    align-items: center;

    .suffix-btn {
        margin-left: -1px !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        // background-color: $primary-color !important;
        border: 1.5px solid red;

        span {
            margin: 0 !important;
            font-size: 8px;

            &::before {
                color: white;
            }
        }

        &.ant-btn-default {
            span {
                &::before {
                    color: $primary-color;
                }
            }
        }
    }

    &.single-btn {
        .ant-btn {
            border-bottom-right-radius: 10px !important;
            border-top-right-radius: 10px !important;
        }
    }
}

.activity-dropdown {
    border-radius: 10px;

    .ant-dropdown-menu {
        padding: 0;
        background: #000;
        border-radius: 10px;
        color: white;
        cursor: pointer;
    }

    .ant-dropdown-menu {
        .ant-dropdown-menu-item {
            padding: 0.5rem 1rem;
        }
    }

    &.primary {
        background: $primary-color;

        .ant-dropdown-menu {
            background: $primary-color;
            color: white;

            .ant-dropdown-menu-item {
                color: white;
                background: $primary-color !important;
                border-radius: 10px;

                &:hover {
                    background: #257264 !important;
                }
            }
        }
    }

    &.default {

        .ant-dropdown-menu {
            background: rgb(246, 246, 246);
            color: $primary-color;

            .ant-dropdown-menu-item {
                color: $primary-color;
                background: rgb(246, 246, 246) !important;
                border-radius: 10px;

                &:hover {
                    background: rgb(241, 241, 241) !important;
                }
            }
        }
    }

    &.secondary {
        .ant-dropdown-menu {
            .ant-dropdown-menu-item {
                color: $primary-color;
            }
        }
    }
}