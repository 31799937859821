@import "src/styles/variables";

.delete-confirmation {
  .delete-confirmation__header {
    font-size: 18px;
    font-weight: 700;
    color: $secondary-color;
  }

  .delete-confirmation--img {
    text-align: center;
    padding-top: 2rem;

    i {
      font-size: 50px;
    }
  }

  .delete-confirmation__content {
    margin: 1rem auto 1rem;
    width: 80%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;

    span {
      color: #2989C9;
      margin-bottom: 2rem;
    }
  }

  .delete-confirmation--hint {
    font-size: 16px;
    color: $menu-font-color;
    text-align: center;
  }

  .delete-confirmation__actions {
    text-align: center;
    margin-top: 15px;
  }

  .delete-confirmation__action {
    margin: 0 1%;
    width: 48% !important;

    &.ant-btn-primary {
      background: $close-color !important;
    }
  }
}