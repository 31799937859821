@import "src/styles/variables";

.app-sidebar {
  // position: fixed;
  width: 17%;
  height: 100vh;
  background-color: #252936;
  box-shadow: $primary-shadow;
  padding: 2rem 0;
  z-index: 1;

  .app-sidebar__menubar {
    width: 99%;
    position: relative;
    background-color: #252936;
    color: $menu-font-color;
  }

  .app-sidebar__menubar__logo {
    text-align: center;
    margin: 24px 0 45px 0;

    img {
      width: 60%;
    }
  }

  .app-sidebar__menubar.ant-menu:not(.ant-menu-horizontal) {
    position: relative;
    height: calc(100vh - 9rem);
    border: none;

    .ant-menu-item {
      padding: 5px 0 5px 1rem !important;
      height: auto;
      margin: 0 0 0.2rem;
      border-left: 4px solid #252936;
      transition: all .5s;

      &:hover {
        color: $menu-font-color;

        .menu-icon::before {
          color: $icon-hover-color;
        }
      }

      &.ant-menu-item-selected {
        // position: relative;
        background: transparent;
        color: inherit;

        &:after {
          border: none;
        }
      }

      &.active {
        background: #303645;
        color: #ffffff;
        border-left: 4px solid $icon-hover-color;
      }

      .menu-icon {
        font-size: 14px;

        &::before {
          transition: all .5s;
        }
      }

      .icon-report-placeholder {
        font-size: 15px;
        margin-right: 7px;
      }

      .icon-user-management {
        font-size: 12px;
      }
    }
  }

  .app-sidebar__menu-title {
    display: inline-block;
    margin-left: 16px;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item.app-sidebar__profile {
    padding: 0.5rem 1rem 0 !important;
    position: absolute;
  }

  .app-sidebar__profile {
    border-radius: 6px;
    width: 90% !important;
    margin: auto !important;
    left: 0;
    right: 0;
    background: #303645;
    bottom: -10vh !important;
  }

  .active-icon::before {
    color: $icon-hover-color !important;
  }
}

.app-sidebar__profile-img {
  display: inline-block;
  width: 22%;
  border-radius: 50%;
  margin-right: 4%;

  img {
    width: 100%;
  }
}

.app-sidebar__profile-info {
  display: inline-block;
  width: 74%;
  vertical-align: top;

  .app-sidebar__username {
    margin: 10px 0 0 0;
    line-height: 0.2rem;
    font-weight: 500;
    cursor: pointer;
    color: #FFFFFF;
  }

  .app-sidebar__profile-role {
    color: #949EA9;
    font-size: 12px;
    text-transform: capitalize;
  }
}

.profile-menu__item {
  margin: 10px 0;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;

  span {
    margin-left: 10px;
  }
}

.ant-popover-placement-top {
  .ant-popover-arrow {
    display: none !important;
  }

  .ant-popover-inner {
    background: #303645 !important;
    padding: 12px 25px !important;
    box-shadow: 0px 0px 10px #00000033 !important;

    .ant-popover-inner-content {
      color: #FFFFFF !important;
    }
  }
}

.profile-menu-icon::before {
  color: $icon-hover-color !important;
}

.profile-menu {
  .ant-divider {
    border-bottom: 0.5px solid #5C637050 !important;
  }
}

.profile-menu__details {
  .app-sidebar__profile-info {
    vertical-align: middle;
  }

  .app-sidebar__profile-role {
    margin-top: 10px;

    .app-sidebar__profile-role__name {
      color: #949EA9;
      font-size: 12px;
    }

    .app-sidebar__profile-role__separator {
      margin: 0 5px;
      font-size: 22px;
      line-height: 0;
    }
  }
}