@import "./../../../styles/variables";

.phone-number-input {

    .PhoneInput {
        border: 1px solid $table-border;
        display: flex;
        height: 40px;
        border-radius: 12px;
        padding-left: 20px !important;
        overflow: hidden;

        .PhoneInputCountry {
            .PhoneInputCountryIconUnicode {
                font-size: 22px;
            }

        }

        input {
            border: 0;
            outline: 0;
            height: 100%;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
        }
    }
}