@import "./../../../styles/variables";


.container-form__header {
    width: 100%;
    margin: 0 !important;
    padding: 1rem;
    box-shadow: 0px 3px 10px #3E3F5E0F !important;
    border-radius: 0px 4px 4px 0px;
    justify-content: space-between;
    align-items: center;
    background: white;
    transition: all 400ms ease-out;

    &.quote {
        border-left: 3px solid $activity-quote-bg;

        .container-form__header--details {
            .container-form__header--icon {
                background: $activity-quote-bg;

                i::before {
                    color: $activity-quote;
                }
            }
        }

    }

    &.repair {
        border-left: 3px solid $activity-repair-bg;

        .container-form__header--details {
            .container-form__header--icon {
                background: $activity-repair-bg;

                i::before {
                    color: $activity-repair;
                }
            }
        }

    }

    .container-form__header--details {
        display: flex;

        .container-form__header--icon {
            margin: 0 1rem 0 0;
            width: 40px;
            height: 40px;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            background: #000;

        }

        p {
            text-transform: capitalize;
            margin: 0;
        }

        .form-type {
            font-size: 12px;
            color: $form-label-color;
        }

        .form-detail {
            font-size: 16px;
            font-weight: 500;
            text-shadow: 0 0 0 black;
            display: flex;

            hr {
                margin: .5rem .75rem;
                width: 5px;
                height: 5px;
                border: none;
                border-radius: 10px;
                background: #E9EDF0;
            }

            .form-detail--date {
                font-size: 15px;
                font-style: italic;
                font-weight: 400;
                text-shadow: none;
                color: $form-label-color;

                i::before {
                    padding: 1rem 1rem 1rem .25rem;
                }
            }
        }
    }

    .ant-col {
        .status {
            margin-right: 3rem;
        }
    }

    .container-form--menu {
        padding: 1rem;
        position: absolute;
        right: 0;
        transform: rotate(90deg);
    }

    .container-form--download {
        padding: 0 .3rem;
        width: 30px;
        position: absolute;
        right: 2.25rem;
        cursor: pointer;

        .anticon {
            color: #43c9b1 !important;
        }
    }
}


.activity-form-menu {
    border: 0 !important;
    height: fit-content;
    max-height: 350px;
    overflow-y: scroll;
    border-radius: 12px !important;

    &::-webkit-scrollbar {
        display: none;
    }

    &:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: transparent;
    }

    .status-item {
        text-transform: capitalize;
    }

    .ant-menu-item:focus,
    .ant-menu-item:hover {
        background: $table-even-row-bg;
    }

    li {
        padding: .6rem .25rem 0.25rem 1rem;
        margin: .25rem .5rem;
        width: 150px;
        height: 40px;
        color: #000;
        font-size: 13px;
        font-weight: 500;
        border-radius: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        i {
            margin-right: .5rem;
        }

        &.delete-form {
            color: $danger-color !important;

            i::before {
                color: $danger-color !important;
            }
        }

        &.change-form {
            color: $dark-grey !important;

            i::before {
                color: $dark-grey !important;
            }
        }

        &:hover,
        &:focus {
            color: #000;
        }
    }
}