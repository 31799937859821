@import "./../../../styles/variables";

.customer-dashboard__container {
    .title-helper {
        margin: -2rem 2rem 2rem;
        color: #949EA9;
        font-size: 16px;

        b {
            margin: 0 .5rem;
            color: #000;
            font-size: 18px;
            font-weight: bold;
        }

        .chip {
            padding: .25rem .5rem;
            color: $primary-color;
            border-radius: 7px;
            background: #ABEBE0;
        }
    }

    .customer-dashboard__table-wrapper {
        padding: 2rem;
        background: #F8FAFB;

        .table-component .ant-table {
            .ant-table-container {
                .ant-table-content {
                    .ant-table-thead {
                        .ant-table-cell {
                            font-weight: bold !important;
                        }
                    }
                }
            }
        }
    }

}