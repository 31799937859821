@import "./../../../styles/variables";

.customer-list__container {

    .customer-list__table-header {
        padding: 0 2rem 2rem;
    }

    .customer-list__table {
        padding: 0 2rem;

    }
}