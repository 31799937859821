@import "./../../../../styles/variables";

.container-comment-list {
    position: relative;
    min-height: calc(100vh - 175px - 47px);

    .container-comment-list__actions {
        padding: 2rem 0;
        width: 65vw;
        max-width: 1000px;
    }

    .container-comment-list__comments {
        padding: 1rem 0;
    }

    .container-comment-list__comments-empty {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 7rem 1rem 1rem;
        width: fit-content;
        color: $form-label-color;
        font-size: 16px;
        background: url("./../../../../assets/single\ color\ icons\ -\ SVG/chatbox.svg");
        background-size: 110px 110px;
        background-position: top center;
        background-repeat: no-repeat;

        p {
            margin: 0;
        }
    }
}