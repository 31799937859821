@import "./../../../styles/variables";

.container-body__container {

    &.quote {
        border-left: 3px solid $activity-quote-bg;
    }

    &.repair {
        border-left: 3px solid $activity-repair-bg;
    }

    .table-component {

        .ant-table-content {
            border-radius: 0px !important;
            border-top: 0 !important;
            border-left: 0 !important;
            border-right: 0 !important;

            table {

                .ant-table-tbody tr:last-child td:first-child,
                .ant-table-tbody tr:last-child,
                .ant-table-thead,
                .ant-table-thead tr th:first-child {
                    border-radius: 0px !important;

                }
            }
        }

    }

    .table-footer {
        text-align: right;
        padding: 1rem;

        span {
            text-shadow: 0px 0px 0px black;
            display: inline-block;
        }

    }
}