@import './../../../styles/variables';

.ant-modal.ui-modal.repair-details-modal {
    width: 600px !important;

    .ant-modal-content {
        padding: 0;

        .ant-modal-header {
            border-radius: 12px;
            padding: 2.5rem 2.5rem 2rem;
        }

        .ant-modal-body {
            padding: 0 0 2.5rem;

            .ant-tabs {

                .ant-tabs-nav {

                    .ant-tabs-nav-wrap {

                        .ant-tabs-nav-list {
                            padding: 0 2.5rem;
                            width: 100%;
                            background: $tab-header-bg;

                            .ant-tabs-tab {

                                .ant-tabs-tab-btn {
                                    font-size: 18px;
                                    color: #000;

                                    .icon-done::before {
                                        margin: 0.25rem .5rem 0;
                                    }
                                }
                            }

                            .ant-tabs-ink-bar {
                                background: $primary-color;
                            }
                        }
                    }
                }

                .ant-tabs-content-holder {
                    padding: 0 2.5rem;

                    .ant-tabs-content {
                        .repair-details__container {

                            .repair-details--heading {
                                margin-top: 1rem;
                                color: $form-label-color;

                                &.na {
                                    color: #000;
                                }
                            }

                            .repair-details__cover {
                                margin: 1rem 0 0;
                                padding: 1.5rem 1.5rem 0.75rem;
                                background: $file-upload-bg;
                                border: 0.5px solid $table-border;
                                border-radius: 10px;

                                &>.ant-col {
                                    margin-bottom: .75rem;
                                }

                                .repair-details--title,
                                .repair-details--value {
                                    font-size: 14px;
                                    font-weight: 300;
                                }

                                .repair-details--value {
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}