@import "./../../../styles/variables";

.invoice-details__container {

    .invoice-details__header {
        position: relative;

        .invoice-details--title {
            display: inline-flex;
            align-items: center;

            .status {
                padding: 0rem .5rem;
                margin-left: .5rem;
                display: inline;
                background-color: $status-danger-bg;
                color: $danger-color;
                font-weight: 500;
                font-size: 13px;
            }
        }

        .invoice-details__header__actions {
            position: absolute;
            top: 50%;
            right: 2rem;

            .ant-btn {
                margin-left: 1rem;

                .icon-mail {
                    font-size: 14px;
                }

                &.secondary {
                    background: $action-btn-bg;

                    span {
                        color: white;
                    }
                }
            }
        }
    }

    .invoice-details__body {
        padding: 0 2rem;
        flex-wrap: nowrap;

        .ant-col {
            width: 100%;
            padding: 1.5rem 1.5rem .5rem;
            border-radius: 10px;
            background: $file-upload-bg;

            .text-grey {
                margin-bottom: .5rem;
                color: $form-label-color;
            }

            .text-semibold {
                margin-bottom: .5rem;
                text-shadow: 0 0 0 black;
            }

            &:not(.ant-col:first-child) {
                margin: 0 0 0 1rem;
            }

            .desc {
                display: -webkit-box;
                font-size: 12px;
                max-width: 100%;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;

            }
        }

        .row {
            display: flex;
            margin: 0;

            .title,
            .value {
                width: 50%;
            }

            .value {
                text-shadow: 0 0 0 black;
                text-align: right;
            }
        }
    }

    .invoice-details__list {
        padding: 2rem;

        .ant-table-container {
            .ant-table-content {
                border-bottom-left-radius: 0 !important;
                border-bottom-right-radius: 0 !important;

                table {

                    .ant-table-thead,
                    .ant-table-tbody {

                        th,
                        tr {

                            .ant-table-cell:nth-child(6),
                            .ant-table-cell:nth-child(7),
                            .ant-table-cell:nth-child(8) {
                                text-align: right;
                            }

                            .ant-table-cell:nth-child(8) {
                                padding-right: 1rem;
                            }
                        }
                    }
                }
            }
        }

        .ant-table-footer {
            border: 0.5px solid $table-border;
            border-top: none;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
        }

        .invoice-details__list--footer {
            justify-content: flex-end;

            .ant-col {
                padding: .5rem .5rem 0 0;
                width: 300px;

                .row {
                    display: flex;
                    margin: 0;

                    .title,
                    .value {
                        width: 50%;
                    }

                    .value {
                        text-shadow: 0 0 0 black;
                        text-align: right;
                    }
                }
            }
        }
    }
}