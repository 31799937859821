@import "src/styles/variables";

.confirmation-modal {

  .confirmation-modal--img {
    text-align: center;
    padding-top: 1rem;

    img {
      width: 100px;
    }

    i {
      font-size: 50px;
    }
  }

  .confirmation__content-modal {
    margin: 1rem auto 1rem;
    width: 80%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;

    span {
      color: #2989C9;
      margin-bottom: 2rem;
    }
  }

  .confirmation-modal--hint {
    font-size: 16px;
    color: $menu-font-color;
    text-align: center;
  }

  .confirmation__actions-modal {
    text-align: center;
    margin-top: 15px;
  }

  .confirmation__action-modal {
    margin: 0 1%;
    width: 48% !important;

    &.ant-btn-primary {
      background: $primary-color !important;
    }
  }
}