.ui-modal.container-comment-form__modal {
    .ant-modal-content {

        .ant-modal-body {
            .input-field {
                textarea {
                    height: 100px !important;

                    &:disabled {
                        background-color: white;
                        color: black;
                        cursor: auto;
                        border: none;
                        resize: none;
                    }
                }
            }
        }

        .ant-modal-footer {
            padding: .5rem 1.5rem 1rem;
        }
    }

    &.form-pristine {
        .ant-modal-content {

            .ant-modal-footer {
                display: none;
            }
        }
    }
}