@import "./../../../styles/variables";

.approve-quotes-modal {

    .ant-modal-content {
        padding-top: 6rem;
        text-align: center;
        background-image: url("./../../../assets/single\ color\ icons\ -\ SVG/resize.svg");
        background-repeat: no-repeat;
        background-size: 70px 70px;
        background-position: center 40px;

        .ant-modal-body {
            padding: 2rem 1rem;

            .title {
                margin: 0 0 .5rem;
                font-size: 24px;
                font-weight: bold;
            }

            .subtitle {
                font-size: 16px;
                color: $form-label-color
            }
        }
    }
}